<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import VoixWysiwyg from '@voix/components/frontend/VoixWysiwyg.vue'

const props = defineProps<{
  inView: boolean
  slice: SliceInterface
  fields: {
    alignment: VoixSelectFieldInterface
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    headingTag: VoixSelectFieldInterface
    headingSize: VoixSelectFieldInterface
    copy: VoixWysiwygFieldInterface
    copySize: VoixSelectFieldInterface
    noWidth: VoixCheckboxFieldInterface
    showTopBorder: VoixCheckboxFieldInterface
    showBottomBorder: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Title Paragraph', group: 'Copy', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesCopyTitleParagraph.jpg',
  fields: {
    alignment: {
      type: 'select',
      label: 'Alignment',
      options: {
        'text-left': 'Left',
        'text-center': 'Center',
        'text-right': 'Right',
      },
      default: 'text-left',
    },
    subtitle: {
      type: 'text',
      label: 'Subtitle',
      group: 'Title',
    },
    title: {
      type: 'text',
      label: 'Title',
      group: 'Title',
    },
    headingTag: {
      type: 'select',
      label: 'Heading Tag',
      group: 'Title',
      options: {
        h1: 'H1',
        h2: 'H2',
        h3: 'H3',
        h4: 'H4',
        h5: 'H5',
        h6: 'H6',
        div: 'div',
      },
      default: 'h2',
    },
    headingSize: {
      type: 'select',
      label: 'Heading Size',
      group: 'Title',
      options: {
        'heading-1': 'Heading 1',
        'heading-2': 'Heading 2',
        'heading-3': 'Heading 3',
        'heading-4': 'Heading 4',
        'heading-5': 'Heading 5',
        'heading-6': 'Heading 6',
        '': 'None',
      },
      default: 'heading-2',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
      group: 'Copy',
    },
    copySize: {
      type: 'select',
      label: 'Copy Size',
      group: 'Copy',
      options: {
        'body-xs': 'Body XS',
        'body-sm': 'Body SM',
        'body': 'Body MD',
        'body-lg': 'Body LG',
        'body-xl': 'Body XL',
        '': 'None',
      },
      default: 'body',
    },
    noWidth: {
      type: 'checkbox',
      label: 'No Width Constraint',
      group: 'Copy',
    },
    showTopBorder: {
      type: 'checkbox',
      label: 'Show Top Border',
      group: 'Borders',
    },
    showBottomBorder: {
      type: 'checkbox',
      label: 'Show Bottom Border',
      group: 'Borders',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      subtitle: {
        value: 'Subtitle',
      },
      title: {
        value: 'Title',
      },
      copy: {
        value: '<p>Paragraph stuff</p>',
      },
    },
  }],
})

const textColor = computed(() => {
  const settings = props.slice.properties?.settings
  if (settings && settings[0]) {
    return settings[0].color
  }

  return null
})
</script>

<template>
  <div class="container px-8 sm:px-0">
    <div
      class="relative z-10 pointer-events-auto flex flex-col border-neutral-800" :class="[
        fields.alignment.value,
        {
          'items-start text-left': fields.alignment.value === 'text-left',
          'items-center text-center': fields.alignment.value === 'text-center',
          'items-end text-right': fields.alignment.value === 'text-right',
          'border-t pt-12 mt-12': fields.showTopBorder.value,
          'border-b pb-12 mb-12': fields.showBottomBorder.value,
        },
      ]"
    >
      <component
        :is="fields.headingTag.value"
        class="duration-500 ease-out delay-300 w-full mx-auto"
        :class="[
          fields.headingSize.value,
          {
            'translate-y-8 opacity-0': !inView,
            'text-left': fields.alignment.value === 'text-left',
            'text-center': fields.alignment.value === 'text-center',
            'text-right': fields.alignment.value === 'text-right',
            'max-w-md': fields.copySize.value === 'body-sm' || fields.copySize.value === 'body-xs',
            'max-w-3xl': fields.copySize.value === 'body',
            'max-w-5xl': fields.copySize.value === 'body-lg',
            'max-w-7xl': fields.copySize.value === 'body-xl',
            'px-8 lg:px-0 !max-w-none': fields.noWidth.value,
          },
        ]"
      >
        <div class="subtitle">
          {{ fields.subtitle.value }}
        </div>
        {{ fields.title.value }}
      </component>
      <div>
        <VoixWysiwyg
          :field="fields.copy"
          class="w-full body-color duration-500 ease-out delay-500"
          :class="[
            fields.copySize.value,
            {
              'textColor': !textColor,
              'translate-y-8 opacity-0': !inView,
              'text-center': fields.alignment.value === 'text-center',
              'text-left': fields.alignment.value === 'text-left',
              'text-right': fields.alignment.value === 'text-right',
              'px-8 lg:px-0': fields.noWidth.value,
            },
          ]"
          :style="{ color: textColor }"
        />
      </div>
    </div>
  </div>
</template>
